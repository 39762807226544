@font-face {
  font-family: Request;
  src: url("./assets/fonts/Request.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  min-height: 100%;
  width: 100%;
  overflow-x: hidden;
  font-family: "PT Mono", monospace;
  background-color: #000000;
}
div,
main,
section {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Request", sans-serif;
}
p {
  font-family: "IBM Plex Mono", sans-serif;
}


.terms-outer {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  color: white;
}

.terms-inner {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-flow: column;
  padding-left: 1rem;
  padding-right: 1rem;
  color: white;
}

.terms-inner p {
  text-align: left;
}

.terms-inner li {
  text-align: left;
}